import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Container, Card, Flex, Box, Link } from 'theme-ui';
import { PageStructure } from '../../components/help-center/PageStructure';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};

const MDXLayout = props => <PageStructure layoutProps={props} title="Google Review Link Not Working">
        {props.children}
    </PageStructure>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`There are two places where you can retrieve a link that will take you directly to the Google Review submission page.`}</p>
    <p>{`One source is the Google My Business dashboard and the other is the Google My Business API. It is ok if you don’t know what an API is. Just remember that is where Simple Review Management gets its Google review link for your listing.`}</p>
    <p>{`The Google review link from the My Business API behaves differently for the owners of business listings. The review form does not show when you follow the link. The link from the Google My Business dashboard does not have the same issue.`}</p>
    <p>{`This inconsistent behavior can be alarming for people testing the link prior to sending it to customers. `}</p>
    <p>{`It is reassuring to see the link work for yourself and unnerving when it does not. We get it! So in this article we will explain how to see the link work for yourself.`}</p>
    <h2 {...{
      "id": "why-the-link-is-not-working"
    }}>{`Why the Link is Not Working`}</h2>
    <p>{`The review submission form does not show when you are logged into the Google user account associated with the Google My Business listing.`}</p>
    <p>{`Google knows it is loading the page for the owner of the listing. Google also makes the assumption the owner of a listing isn’t going to post a review for their own business. As a result when loading the page Google shows you what it thinks is more useful content than the review submission form.`}</p>
    <p>{`You can tell this has happened when your listing contains the following “You manage this Business Profile”.`}</p>
    <h2 {...{
      "id": "how-to-get-it-to-work"
    }}>{`How to Get it to Work`}</h2>
    <p>{`You will be able to see the review link properly show the review submission form if you view it from any Google user account that is not associated with the Google Business Listing.`}</p>
    <p>{`If you have a second Google account that is not associated with the Google Business Listing switch over to that one and give the link a try. `}</p>
    <p>{`If you do not have an additional Google user account another option is to ask someone you know to try the link on their device. Again, if it is a colleague, make sure their Google user account is not also associated with the Google My Business listing.`}</p>
    <p>{`Unfortunately you can not view the Google review submission form without being logged into a Google user account. So, if none of the other options are suitable you will have to `}<a parentName="p" {...{
        "href": "https://www.google.com/account/about/"
      }}>{`create a new Google account`}</a>{` to test with.`}</p>
    <p>{`Using one of these three methods you will be able to test the Google review link, see for yourself it is working and have confidence using it to request reviews from your customers.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      